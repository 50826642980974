import { AuthProvider, AuthProviderProps } from "oidc-react";
import { CssBaseline } from "@mui/material";
import { IAppConfig } from "./api/restModel";
import { SnackbarProvider } from "notistack";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { axiosInstance, getAppConfig } from "./api/restFacade";
import { determineAPIUrl } from "./utils/environmentUtils";
import { useNavigate } from "react-router";
import MainApp from "./MainApp";
import React, { useEffect, useRef, useState } from "react";
import zebTheme from "./theme/zebTheme";

const POST_LOGOUT_REDIRECT_URI = "https://zeb-control.com/de-DE";

export default function App(): React.ReactElement {
  const [authProviderConfig, setAuthProviderConfig] = useState<AuthProviderProps | null>(null);
  const navigate = useNavigate();

  const redirectUri = useRef<string>(`${window.location.origin}/`);

  useEffect(() => {
    getAppConfig()
      .then((result) => {
        // set baseUrl for all further calls to api-prefix from app config endpoint
        axiosInstance.defaults.baseURL = `${determineAPIUrl(window.location)}${result?.["api-path-prefix"]}`;

        const config = buildAuthProviderConfig(result);
        setAuthProviderConfig(config);
      })
      .catch((err) => {
        console.error("Error while trying to configure authentication provider.", JSON.stringify(err));

        console.debug("Performing second attempt on legacy api baseUrl");
        axiosInstance.defaults.baseURL = determineAPIUrl(window.location, true);

        getAppConfig()
          .then((result) => {
            const config = buildAuthProviderConfig(result);
            setAuthProviderConfig(config);
          })
          .catch((err) => {
            console.error("Error while trying to configure authentication provider with legacy api.", JSON.stringify(err));
          });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buildAuthProviderConfig = (appConfig: IAppConfig | null): AuthProviderProps => {
    const authority = appConfig?.["idp-well-known-configuration"].includes(".well-known/openid-configuration")
      ? appConfig["idp-well-known-configuration"].split(".well-known/openid-configuration")[0]
      : "";

    const authProviderConfig: AuthProviderProps = {
      authority: authority,
      clientId: appConfig ? appConfig["client-id"] : "",
      scope: "profile email openid",
      responseType: "code",
      redirectUri: redirectUri.current,
      autoSignIn: true,
      automaticSilentRenew: true,
      postLogoutRedirectUri: POST_LOGOUT_REDIRECT_URI,
    };
    return authProviderConfig;
  };

  const onSignInCallback = () => {
    navigate("/");
  };

  return (
    <>
      {authProviderConfig ? (
        <AuthProvider {...authProviderConfig} onSignIn={onSignInCallback}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={zebTheme}>
              <SnackbarProvider maxSnack={3}>
                <CssBaseline />
                <MainApp />
              </SnackbarProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </AuthProvider>
      ) : null}
    </>
  );
}
