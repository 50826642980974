import { IAWSService, IServiceStack } from "./api/restModel";
import { Theme } from "@mui/material/styles";
import { createEmptyStartStopConfig, showAdministration } from "./utils/commonUtils";
import {
  createEnvironment,
  createServiceForEnvironment,
  createStartStopConfig,
  deleteEnvironment,
  deleteService,
  getAllEnvironments,
  getEnvironment,
} from "./api/restFacade";
import { zebStyles as parentZebStyles } from "./theme/zebstyles";
import { useAuth } from "oidc-react";
import { useSnackbar } from "notistack";
import AddEnvironmentDialog from "./Dialogs/AddEnvironmentDialog";
import AddInstanceDialog from "./Dialogs/AddAWSInstanceDialog";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Collapse from "@mui/material/Collapse";
import DeleteDialog from "./Dialogs/DeleteDialog";
import EditServiceDialog from "./Dialogs/EditServiceDialog";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Paper from "@mui/material/Paper";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import useAPIError from "./Dialogs/error/useAPIError";

const useStyles = makeStyles((theme: Theme) => ({
  ...parentZebStyles(theme),
  mainRow: {
    "& > *": {
      borderBottom: "unset",
      padding: theme.spacing(0),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(0),
    },
  },
  detailRow: {
    "& > *": {
      borderBottom: "unset",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(0),
    },
  },
  detailHeader: {
    "& > *": {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
    },
  },
  mainHeader: {
    "& > *": {
      padding: theme.spacing(0),
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),
    },
  },
  detailTable: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(7),
    width: 800,
  },
  mainTable: {
    padding: theme.spacing(0),
    boxSizing: "unset",
  },
  paper: {
    padding: 0,
  },
}));

function Row(props: {
  index: number;
  servicestack: IServiceStack;
  callbackFunctionDeleteEnv: (
    environmentId: string,
    displayname: string,
    showWaitingIndicator: (w: boolean) => void
  ) => void;
  callbackFunctionCheckServiceAlreadyAssigned: (nameofnewservice: string) => string | undefined;
}) {
  const [environment, setEnvironment] = useState(props.servicestack);
  const [rowDetailOpen, setOpen] = useState(getRowOpend());
  const { enqueueSnackbar } = useSnackbar();
  const { addError } = useAPIError();
  const [waitingEnv, setWaitingEnv] = useState(false);
  const [waitingService, setWaitingService] = useState(false);
  const auth = useAuth();

  const classes = useStyles();

  function refreshEnvironment() {
    getEnvironment(environment.servicestackid, auth)
      .then((env) => {
        if (env !== null) {
          console.log("newly fetched:", env);
          setEnvironment(env);
        } else {
          addError("error loading environment. Environment could be deleted meanwhile", "error");
        }
      })
      .catch(() => addError("error loading environment", "error"));
  }

  function handleAddInstance(
    servicename: string,
    serviceType: string,
    additional_settings: Record<string, unknown> | undefined
  ) {
    setWaitingService(true);
    createServiceForEnvironment(environment.servicestackid, servicename, serviceType, additional_settings, auth)
      .then((restResult) => {
        refreshEnvironment();
        setWaitingService(false);
        enqueueSnackbar(restResult.message, { variant: "info" });
      })
      .catch((restResult) => {
        addError(restResult.message, "error");
        setWaitingService(false);
      });
  }

  function handleDeleteEnvironment(id: string) {
    props.callbackFunctionDeleteEnv(id, environment.displayname, setWaitingEnv);
  }

  function getRowOpend(): boolean {
    const result = localStorage.getItem("adminrow_" + props.index);

    if (result === null) {
      localStorage.setItem("adminrow_" + props.index, "false");
      return false;
    } else {
      return result === "true" ? true : false;
    }
  }

  function handleOpenDetail() {
    setOpen(!rowDetailOpen);
    localStorage.setItem("adminrow_" + props.index, (!rowDetailOpen).toString());
  }

  function ServiceRow(props: { service: IAWSService; servicestackid: string; index: number }) {
    const [waitingDeleteService, setWaitingDeleteService] = useState(false);

    function handleDeleteInstance(servicename: string) {
      setWaitingDeleteService(true);

      deleteService(environment.servicestackid, servicename, auth)
        .then((restResult) => {
          refreshEnvironment();
          enqueueSnackbar(restResult.message, { variant: "info" });
          setWaitingDeleteService(false);
        })
        .catch((restResult) => {
          addError(restResult.message, "error");
          setWaitingDeleteService(false);
        });
    }

    if (!showAdministration(auth)) {
      return <div>You do not have the necessary rights</div>;
    }

    return (
      <React.Fragment>
        <TableRow key={props.index} className={classes.detailRow}>
          <TableCell align="left" style={{ width: 20 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {waitingDeleteService ? (
                <CircularProgress className={classes.circularProgress} size={20} />
              ) : (
                <DeleteDialog
                  idOfobjectToDelete={props.service.servicename}
                  name={props.service.servicename}
                  callbackFunction={handleDeleteInstance}></DeleteDialog>
              )}
              {(props.service.servicetype === "AUTOSCALING-GROUP" || props.service.servicetype === "KUBE-NODE-GROUP") && (
                <EditServiceDialog
                  service={props.service}
                  servicestackid={props.servicestackid}
                  onSuccess={refreshEnvironment}
                />
              )}
            </Box>
          </TableCell>
          <TableCell align="left" style={{ width: 250 }}>
            {props.service.servicename}
          </TableCell>
          <TableCell align="left" style={{ width: 200 }}>
            {props.service.servicetype}
          </TableCell>
          <TableCell align="left" style={{ width: 200 }}>
            {props.service.desired_capacity}
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <TableRow className={classes.mainRow}>
        <TableCell align="center" style={{ width: 50 }}>
          <IconButton aria-label="expand row" size="small" onClick={() => handleOpenDetail()}>
            {rowDetailOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left" style={{ width: 40 }}>
          {waitingEnv ? (
            <CircularProgress className={classes.circularProgress} size={20} />
          ) : (
            <DeleteDialog
              idOfobjectToDelete={environment.servicestackid}
              name={environment.displayname}
              callbackFunction={handleDeleteEnvironment}></DeleteDialog>
          )}
        </TableCell>
        <TableCell>
          <Typography>{environment.displayname}</Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 20, paddingTop: 0 }} colSpan={3}>
          <Collapse in={rowDetailOpen} timeout="auto" unmountOnExit>
            <Box margin={1} border={0} className={classes.detailTable}>
              <TableContainer>
                <Table aria-label="detail" className={classes.detailTable}>
                  <TableHead>
                    <TableRow className={classes.detailHeader}>
                      <TableCell align="left" style={{ width: 20 }}>
                        {waitingService ? (
                          <CircularProgress className={classes.circularProgress} size={20} />
                        ) : (
                          <AddInstanceDialog
                            label={""}
                            callbackFunction={handleAddInstance}
                            callbackFunctionCheckServiceAlreadyAssigned={
                              props.callbackFunctionCheckServiceAlreadyAssigned
                            }></AddInstanceDialog>
                        )}
                      </TableCell>
                      <TableCell align="left" style={{ width: 250 }}>
                        Name
                      </TableCell>
                      <TableCell align="left" style={{ width: 200 }}>
                        Type
                      </TableCell>
                      <TableCell align="left" style={{ width: 200 }}>
                        Desired Capacity
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {environment.services.map((instance, index) => (
                      <ServiceRow
                        service={instance}
                        servicestackid={environment.servicestackid}
                        index={index}
                        key={instance.servicename + instance.servicetype}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function Administration() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { addError } = useAPIError();
  const auth = useAuth();

  const [waiting, setWaiting] = useState(false);
  const [waitingLoading, setWaitingLoading] = useState(false);

  const [environments, setEnvironments] = useState<IServiceStack[]>([]);

  useEffect(() => {
    if (auth && auth.userData) {
      loadEnvironments(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  function sortEnvironments(stacks: IServiceStack[]): void {
    stacks.sort(function (a, b) {
      const nameA = a.displayname.toUpperCase(); // Groß-/Kleinschreibung ignorieren
      const nameB = b.displayname.toUpperCase(); // Groß-/Kleinschreibung ignorieren
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      return 0;
    });
  }

  function loadEnvironments(showIndicator: boolean) {
    if (showIndicator) setWaitingLoading(true);

    getAllEnvironments(auth)
      .then((data) => {
        if (data !== null && data.servicestacks !== null && data.servicestacks.length > 0) {
          sortEnvironments(data.servicestacks);
          setEnvironments(data.servicestacks);
        } else {
          setEnvironments([]);
          enqueueSnackbar("No Environments found", { variant: "warning" });
        }

        setWaitingLoading(false);
      })
      .catch(() => {
        addError("Error while loading environments", "error");
        setWaitingLoading(false);
      });
  }

  function handleAddEnvironment(environmentId: string, environmentName: string) {
    setWaiting(true);

    //create env, then startconfig, then stopconfig. must be serial processed because requests are not queued in server

    createEnvironment(environmentId, environmentName, auth)
      .then((restResult) => {
        createStartStopConfig(environmentId, createEmptyStartStopConfig("start", 0), auth)
          .then(() => {
            createStartStopConfig(environmentId, createEmptyStartStopConfig("stop", 1), auth)
              .then(() => {
                loadEnvironments(false);
                enqueueSnackbar(restResult.message, { variant: "info" });
                setWaiting(false);
              })
              .catch((restResult) => addError(restResult.message, "error"));
          })
          .catch((restResult) => addError(restResult.message, "error"));
      })
      .catch((restResult) => addError(restResult.message, "error"));
  }

  function handleDeleteEnvironment(id: string, displayname: string, showWaitingIndicator: (w: boolean) => void) {
    showWaitingIndicator(true);
    deleteEnvironment(id, displayname, auth)
      .then((restResult) => {
        enqueueSnackbar(restResult.message, { variant: "info" });
        loadEnvironments(false);
        showWaitingIndicator(false);
      })
      .catch((restResult) => {
        addError(restResult.message, "error");
        showWaitingIndicator(false);
      });
  }

  function checkServiceInEnvironment(nameofnewservice: string): undefined | string {
    let result = undefined;
    environments.forEach((servicestack: IServiceStack) => {
      servicestack.services.forEach((service: IAWSService) => {
        if (service.servicename === nameofnewservice) {
          result = servicestack.displayname;
        }
      });
    });
    return result;
  }

  return (
    <Paper>
      <TableContainer component={Paper} className={classes.mainTable}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow className={classes.mainHeader}>
              <TableCell align="center" style={{ width: 50 }}>
                {waiting || waitingLoading ? <CircularProgress className={classes.circularProgress} size={20} /> : ""}
              </TableCell>
              <TableCell align="left" style={{ width: 40 }}>
                <AddEnvironmentDialog
                  label={""}
                  callbackFunction={handleAddEnvironment}
                  disabled={waiting || waitingLoading}
                />
              </TableCell>
              <TableCell>Environment</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {environments.map((environment, index) => (
                <Row
                  index={index}
                  key={environment.servicestackid}
                  servicestack={environment}
                  callbackFunctionDeleteEnv={handleDeleteEnvironment}
                  callbackFunctionCheckServiceAlreadyAssigned={checkServiceInEnvironment}
                />
              ))}
            </>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
