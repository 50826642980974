import { Theme } from "@mui/material/styles";
import { zebStyles as parentZebStyles } from "../theme/zebstyles";
import { v4 as uuidv4 } from "uuid";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import React from "react";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) => ({
  ...parentZebStyles(theme),
}));

export default function AddEnvironmentDialog(props: {
  label: string;
  disabled: boolean;
  callbackFunction: (environmentId: string, environmentName: string) => void;
}) {
  const [open, setOpen] = React.useState(false);

  const [environmentName, setEnvironmentName] = React.useState("");

  const classes = useStyles();

  const handleClickOpen = () => {
    setEnvironmentName("");
    setOpen(true);
  };

  const handleAdd = () => {
    props.callbackFunction(uuidv4(), environmentName);

    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeEnvironmentName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnvironmentName(event.target.value.trim());
  };

  return (
    <div>
      <Typography variant="subtitle1">{props.label}</Typography>
      <Tooltip title="Add Environment">
        <IconButton
          className={classes.iconButton}
          size="small"
          aria-label="add Environment"
          onClick={handleClickOpen}
          disabled={props.disabled}>
          <AddIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add Environment</DialogTitle>
        <DialogContent>
          <TextField
            variant="standard"
            autoFocus
            margin="dense"
            id="name"
            label="Environment Name"
            type="text"
            fullWidth
            value={environmentName}
            onChange={handleChangeEnvironmentName}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAdd} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
