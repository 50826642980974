import { IEnvironmentState, IServiceState, Weekdays } from "./api/restModel";
import { TextField } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { createStartStopConfigBackendFriendly, createStartStopConfigUIFriendly, showStatus } from "./utils/commonUtils";
import { getAllEnvironmentState, startEnvironment, stopEnvironment, updateStartStopConfig } from "./api/restFacade";
import { zebStyles as parentZebStyles } from "./theme/zebstyles";
import { roleExists } from "./utils/commonUtils";
import { useAuth } from "oidc-react";
import { useSnackbar } from "notistack";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Paper from "@mui/material/Paper";
import React, { useEffect, useState } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import StartStopDialog from "./Dialogs/StartStopDialog";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import useAPIError from "./Dialogs/error/useAPIError";

const useRowStyles = makeStyles((theme: Theme) => ({
  ...parentZebStyles(theme),
  mainRow: {
    "& > *": {
      borderBottom: "unset",
      padding: theme.spacing(0),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  mainHeader: {
    "& > *": {
      height: "65px",
      padding: theme.spacing(0),
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),
    },
  },
  mainRowSecond: {
    "& > *": {
      padding: theme.spacing(0),
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(1),
    },
  },
  timeinputLabel: {
    padding: theme.spacing(0),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  timeinput: {
    padding: theme.spacing(0),
    border: 0,
    margin: 0,
  },
  checkbox: {
    padding: theme.spacing(0),
    margin: theme.spacing(0),
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.grey[400],
  },
  schedulerBox: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(0),
  },
  cell: {
    padding: theme.spacing(1),
    margin: 0,
    border: 0,
  },
}));

function Row(props: { index: number; row: IEnvironmentState }) {
  const [row, setRow] = useState(props.row);
  const [open, setOpen] = useState(getRowOpend());
  const auth = useAuth();
  const [startStopConfig, setStartStopConfig] = useState(createStartStopConfigUIFriendly(row.servicestackid, row.schedules));
  const classes = useRowStyles();

  const editAllowed =
    auth != null &&
    auth.userData != null &&
    (roleExists("admin", auth.userData.profile.roles as string[]) ||
      roleExists("poweruser", auth.userData.profile.roles as string[]));

  const currentstate = props.row.currentstate;

  const { enqueueSnackbar } = useSnackbar();
  const { addError } = useAPIError();

  function handleSchedulerChange(
    type: string,
    dayindex: number,
    rowindex: number,
    e: { target: { name: any; checked: any } }
  ) {
    const item = e.target.name;
    const isChecked = e.target.checked;

    const newStartStopConfig = Object.assign({}, startStopConfig);
    const newState = Object.assign({}, row);

    if (item === "start") {
      newStartStopConfig.autoStartEnabled = isChecked;
    } else if (item === "stop") {
      newStartStopConfig.autoStopEnabled = isChecked;
    } else if (type === "start") {
      newStartStopConfig.startDays[dayindex] = isChecked;
    } else if (type === "stop") {
      newStartStopConfig.stopDays[dayindex] = isChecked;
    }

    newState.schedules = createStartStopConfigBackendFriendly(newStartStopConfig);

    if (newStartStopConfig.indexstart < 1) {
    }

    if (type === "start") {
      updateStartStopConfig(row.servicestackid, newState.schedules[0], auth).catch((restResult) =>
        addError(restResult.message, "error")
      );
    } else {
      updateStartStopConfig(row.servicestackid, newState.schedules[1], auth).catch((restResult) =>
        addError(restResult.message, "error")
      );
    }
    setStartStopConfig(newStartStopConfig);
    setRow(newState);
  }

  function handleSchedulerTimeChange(type: string, rowindex: number, e: { target: { name: any; value: any } }) {
    const value = e.target.value;

    const newStartStopConfig = Object.assign({}, startStopConfig);
    const newState = Object.assign({}, row);

    if (type === "start") {
      newStartStopConfig.startTime = value;
      newState.schedules = createStartStopConfigBackendFriendly(newStartStopConfig);
      updateStartStopConfig(row.servicestackid, newState.schedules[0], auth).catch((restResult) =>
        addError(restResult.message, "error")
      );
    } else if (type === "stop") {
      newStartStopConfig.stopTime = value;
      newState.schedules = createStartStopConfigBackendFriendly(newStartStopConfig);
      updateStartStopConfig(row.servicestackid, newState.schedules[1], auth).catch((restResult) =>
        addError(restResult.message, "error")
      );
    }

    setStartStopConfig(newStartStopConfig);
    setRow(newState);
  }

  function handleStopEnvironment() {
    stopEnvironment(row.servicestackid, row.displayname, auth)
      .then((restResult) => {
        enqueueSnackbar(restResult.message, { variant: "info" });
      })
      .catch((restResult) => addError(restResult.message, "error"));
  }

  function handleStartEnvironment() {
    startEnvironment(row.servicestackid, row.displayname, auth)
      .then((restResult) => {
        enqueueSnackbar(restResult.message, { variant: "info" });
      })
      .catch((restResult) => addError(restResult.message, "error"));
  }

  function createServiceStatusString(states: IServiceState[]): string {
    let s = "";
    let i = 0;

    states.forEach((state: IServiceState) => {
      if (i > 0) {
        s = s + ", ";
      }
      s = s + state.servicename + ": " + state.servicestate;
      i++;
    });
    return s;
  }

  function getRowOpend(): boolean {
    const result = localStorage.getItem("envstate_" + props.index);

    if (result === null) {
      localStorage.setItem("envstate_" + props.index, "false");
      return false;
    } else {
      return result === "true" ? true : false;
    }
  }

  function handleOpenDetail() {
    setOpen(!open);
    localStorage.setItem("envstate_" + props.index, (!open).toString());
  }

  return (
    <React.Fragment>
      <TableRow className={classes.mainRow}>
        <TableCell align="center">
          <IconButton aria-label="expand row" size="small" onClick={() => handleOpenDetail()}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">
          {currentstate !== "stopped" ? (
            <StartStopDialog
              type="stop"
              index={props.index}
              name={row.displayname}
              callbackFunction={handleStopEnvironment}
            />
          ) : (
            ""
          )}
          {currentstate === "stopped" ? (
            <StartStopDialog
              type="start"
              index={props.index}
              name={row.displayname}
              callbackFunction={handleStartEnvironment}
            />
          ) : (
            ""
          )}
        </TableCell>
        <TableCell component="th" scope="row">
          <Tooltip title={createServiceStatusString(row.servicestates)}>
            <div>{row.displayname}</div>
          </Tooltip>
        </TableCell>
        <TableCell align="left">{currentstate}</TableCell>
        <TableCell align="left">{currentstate === "started" ? row.laststart : row.laststop}</TableCell>
        <TableCell align="left">
          <AutoStartStopLabel
            days={startStopConfig.startDays}
            time={startStopConfig.startTime}
            autoenabled={startStopConfig.autoStartEnabled}
          />{" "}
        </TableCell>
        <TableCell align="left">
          <AutoStartStopLabel
            days={startStopConfig.stopDays}
            time={startStopConfig.stopTime}
            autoenabled={startStopConfig.autoStopEnabled}
          />{" "}
        </TableCell>
        <TableCell align="left">{currentstate === "started" ? row.startedby : row.stoppedby}</TableCell>
      </TableRow>
      <TableRow className={classes.mainRowSecond}>
        <TableCell />
        <TableCell />
        <TableCell colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box className={classes.schedulerBox}>
              <Typography variant="subtitle1" gutterBottom component="div">
                Service States
              </Typography>

              <Typography variant="caption" gutterBottom component="div">
                {createServiceStatusString(row.servicestates)}
              </Typography>
              <br></br>
              <Typography variant="subtitle1" gutterBottom component="div">
                Scheduler
              </Typography>
              <form>
                {
                  <Scheduler
                    type="start"
                    auto={startStopConfig.autoStartEnabled}
                    days={startStopConfig.startDays}
                    time={startStopConfig.startTime}
                    index={props.index}
                    editAllowed={editAllowed}
                  />
                }
                <br></br>
                {
                  <Scheduler
                    type="stop"
                    auto={startStopConfig.autoStopEnabled}
                    days={startStopConfig.stopDays}
                    time={startStopConfig.stopTime}
                    index={props.index}
                    editAllowed={editAllowed}
                  />
                }
              </form>
              {editAllowed ? (
                <div />
              ) : (
                <Box fontStyle="italic">note: you may not edit because you do not have the necessary rights or role</Box>
              )}
              <br></br>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );

  function AutoStartStopLabel(props: { days: boolean[]; time: string; autoenabled: boolean }) {
    let labelstr = "";
    let weekdaychoosen = false;
    let timechoosen = false;

    if (props.autoenabled) {
      if (props.days[Weekdays.Monday]) {
        labelstr = labelstr + " MO";
        weekdaychoosen = true;
      }
      if (props.days[Weekdays.Tuesday]) {
        labelstr = labelstr + " TU";
        weekdaychoosen = true;
      }
      if (props.days[Weekdays.Wednesday]) {
        labelstr = labelstr + " WD";
        weekdaychoosen = true;
      }
      if (props.days[Weekdays.Thursday]) {
        labelstr = labelstr + " TH";
        weekdaychoosen = true;
      }
      if (props.days[Weekdays.Friday]) {
        labelstr = labelstr + " FR";
        weekdaychoosen = true;
      }
      if (props.days[Weekdays.Saturday]) {
        labelstr = labelstr + " SA";
        weekdaychoosen = true;
      }
      if (props.days[Weekdays.Sunday]) {
        labelstr = labelstr + " SU";
        weekdaychoosen = true;
      }
      if (props.time !== "none" && props.time !== "") {
        labelstr = labelstr + " " + props.time;
        timechoosen = true;
      }
    }
    if (weekdaychoosen && timechoosen) {
      return <label>{labelstr}</label>;
    }
    return <label></label>;
  }

  function Scheduler(props: {
    type: string;
    auto: boolean;
    days: boolean[];
    time: string;
    index: number;
    editAllowed: boolean;
  }) {
    const classes = useRowStyles();

    return (
      <div>
        <input
          className={classes.checkbox}
          name={props.type}
          type="checkbox"
          disabled={!editAllowed}
          checked={props.auto}
          onChange={(e) => handleSchedulerChange(props.type, -1, props.index, e)}
        />

        <label>{props.type === "start" ? "Autostart" : "Autostop"}</label>
        <br></br>
        <input
          className={classes.checkbox}
          name="Mon"
          type="checkbox"
          disabled={!editAllowed}
          checked={props.days[Weekdays.Monday]}
          onChange={(e) => handleSchedulerChange(props.type, Weekdays.Monday, props.index, e)}
        />
        <label style={{ marginRight: 10 }}>Monday</label>
        <input
          className={classes.checkbox}
          name="Tue"
          type="checkbox"
          disabled={!editAllowed}
          checked={props.days[Weekdays.Tuesday]}
          onChange={(e) => handleSchedulerChange(props.type, Weekdays.Tuesday, props.index, e)}
        />
        <label style={{ marginRight: 10 }}>Tuesday</label>
        <input
          className={classes.checkbox}
          name="Wed"
          type="checkbox"
          disabled={!editAllowed}
          checked={props.days[Weekdays.Wednesday]}
          onChange={(e) => handleSchedulerChange(props.type, Weekdays.Wednesday, props.index, e)}
        />
        <label style={{ marginRight: 10 }}>Wednesday</label>
        <input
          className={classes.checkbox}
          name="Thu"
          type="checkbox"
          disabled={!editAllowed}
          checked={props.days[Weekdays.Thursday]}
          onChange={(e) => handleSchedulerChange(props.type, Weekdays.Thursday, props.index, e)}
        />
        <label style={{ marginRight: 10 }}>Thursday</label>
        <input
          className={classes.checkbox}
          name="Fri"
          type="checkbox"
          disabled={!editAllowed}
          checked={props.days[Weekdays.Friday]}
          onChange={(e) => handleSchedulerChange(props.type, Weekdays.Friday, props.index, e)}
        />
        <label style={{ marginRight: 10 }}>Friday</label>
        <input
          className={classes.checkbox}
          name="Sat"
          type="checkbox"
          disabled={!editAllowed}
          checked={props.days[Weekdays.Saturday]}
          onChange={(e) => handleSchedulerChange(props.type, Weekdays.Saturday, props.index, e)}
        />
        <label style={{ marginRight: 10 }}>Saturday</label>
        <input
          className={classes.checkbox}
          name="Sun"
          type="checkbox"
          disabled={!editAllowed}
          checked={props.days[Weekdays.Sunday]}
          onChange={(e) => handleSchedulerChange(props.type, Weekdays.Sunday, props.index, e)}
        />
        <label style={{ marginRight: 10 }}>Sunday</label>
        <label className={classes.timeinputLabel}>Time: </label>
        <TextField
          variant="standard"
          className={classes.timeinput}
          id="time"
          type="time"
          defaultValue={props.time}
          InputLabelProps={{ shrink: true }}
          inputProps={{ step: 300 }}
          disabled={!editAllowed}
          onChange={(e) => handleSchedulerTimeChange(props.type, props.index, e)}
        />
      </div>
    );
  }
}

export default function EnvironmentStates() {
  const auth = useAuth();
  const classes = useRowStyles();
  const [rows, setRows] = useState<IEnvironmentState[]>([]);
  const [waitingLoading, setWaitingLoading] = useState(false);

  useEffect(() => {
    console.debug(`Loading states for auth="${auth}", auth.userData="${auth.userData}"`);
    loadStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!showStatus(auth)) {
    return <div>You do not have the necessary rights</div>;
  }

  function loadStates() {
    async function loadStatus() {
      const data = await getAllEnvironmentState(auth);

      if (data !== null) {
        data.service_stacks.sort(function (a, b) {
          const nameA = a.displayname.toUpperCase(); // Groß-/Kleinschreibung ignorieren
          const nameB = b.displayname.toUpperCase(); // Groß-/Kleinschreibung ignorieren
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          return 0;
        });

        setRows(data.service_stacks);
      }
    }
    setWaitingLoading(true);
    loadStatus()
      .then(() => setWaitingLoading(false))
      .catch(() => setWaitingLoading(false));
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow className={classes.mainHeader}>
            <TableCell style={{ width: 50 }} align="center">
              {waitingLoading ? (
                <CircularProgress className={classes.circularProgress} size={20} />
              ) : (
                <IconButton className={classes.iconButton} size="small" onClick={loadStates}>
                  <Tooltip title="refresh table">
                    <RefreshIcon />
                  </Tooltip>
                </IconButton>
              )}
            </TableCell>
            <TableCell style={{ width: 50 }} />
            <TableCell style={{ width: 250 }}>Environment</TableCell>
            <TableCell style={{ width: 90 }} align="left">
              State
            </TableCell>
            <TableCell style={{ width: 200 }} align="left">
              Since
            </TableCell>
            <TableCell style={{ width: 200 }} align="left">
              Autostart
            </TableCell>
            <TableCell style={{ width: 200 }} align="left">
              Autostop
            </TableCell>
            <TableCell align="left">Start/Stop Operator</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row: IEnvironmentState, index) => (
            <Row index={index} key={JSON.stringify(row)} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
