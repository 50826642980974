import { Theme } from "@mui/material/styles";

export const zebStyles = (theme: Theme) => {
  return {
    iconButton: {
      color: theme.palette.primary.main,
    },
    iconButtonWhite: {
      color: theme.palette.common.white,
    },
    iconButtonGrey: {
      color: theme.palette.grey[500],
    },
    iconButtonBlack: {
      color: theme.palette.common.black,
    },
    iconButtonGreen: {
      // TODO
      color: theme.palette.grey[500],
    },
    iconButtonRed: {
      // TODO
      color: theme.palette.grey[500],
    },
    backgroundGrey: {
      backgroundColor: theme.palette.grey[300],
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    logo: {
      marginRight: theme.spacing(2),
      color: theme.palette.background.paper,
    },
    iconSecondaryColor: {
      color: theme.palette.background.paper,
    },
    appbar: {
      background: theme.palette.primary.main,
    },
    tabs: {
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.grey[600],
      indicator: theme.palette.primary.main,
    },
    tooltip: {
      flexGrow: 1,
    },
    circularProgress: {
      color: theme.palette.primary.main,
      width: 20,
      heigt: 20,
      verticalAlign: "middle",
    },
  };
};
