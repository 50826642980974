import { $enum } from "ts-enum-util";

export enum RunState {
  Stopped = 0,
  Running = 1,
}

export enum Weekdays {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export enum INSTANCETYPES {
  APPSTREAM = "APPSTREAM-FLEET",
  AUTOSCALING_GROUP = "AUTOSCALING-GROUP",
  EC2 = "EC2",
  KUBE_NODE_GROUP = "KUBE-NODE-GROUP",
  K8S_STATEFUL_SET = "K8S-STATEFUL-SET",
  K8S_DEPLOYMENT = "K8S-DEPLOYMENT",
  K8S_CRD = "K8S-CRD",
  RDS = "RDS",
}

export const INSTANCETYPE_DEFAULT = INSTANCETYPES.EC2;

export function instanceTypeValueByKey(pType: INSTANCETYPES): string {
  return $enum(INSTANCETYPES).asValueOrThrow(pType);
}

export interface IEnvironmentStates {
  service_stacks: IEnvironmentState[];
  message: string;
  timestamp: string;
}

export interface IStartStopConfig {
  start: string;
  stop: string;
  cron: string;
  enabled: boolean;
  description: string;
  index: number;
}

export interface IStartStopConfigUIFriendly {
  autoStartEnabled: boolean;
  autoStopEnabled: boolean;
  startTime: string;
  stopTime: string;
  startDays: boolean[]; //0=Sun
  stopDays: boolean[]; //0=Sun
  indexstart: number;
  indexstop: number;
}

export interface IServiceState {
  servicename: string;
  servicetype: string;
  servicestate: string;
}

export interface IEnvironmentState {
  servicestackid: string;
  targetstate: string;
  currentstate: string;
  displayname: string;
  description: string;
  visibilityrole: string;
  servicestates: IServiceState[];
  schedules: IStartStopConfig[];
  laststart: string;
  laststop: string;
  startedby: string;
  stoppedby: string;
  message: string;
}

export interface IEnvironments {
  servicestacks: IServiceStack[];
  message: string;
  timestamp: string;
}

export interface IServiceStack {
  displayname: string;
  servicestackid: string;
  services: IAWSService[];
}

export interface IAWSService {
  servicename: string;
  servicetype: string;
  desired_capacity?: number;
}

export function createAWSService(servicename: string, servicetype: string): IAWSService {
  return {
    servicename,
    servicetype,
  };
}

export interface ILock {
  timestamp: string;
  message: string;
  locksessionid: string;
  locktime: string;
  lockuser: string;
  mylock: boolean;
  havelock: boolean;
  canlock: boolean;
}

export interface IRestResult {
  ok: boolean;
  message: string;
}

export function restResult(ok: boolean, message: string): IRestResult {
  return { ok, message };
}

export interface IProtocolRuntime {
  duration: string;
  starttime: string;
  startuser: string;
  stoptime: string;
  stopuser: string;
  durationhours: number;
}

export interface IProtocolStack {
  stackid: string;
  stackdisplayname: string;
  runtimes: IProtocolRuntime[];
  runtimessumhours: number;
}

export interface IProtocolStacks {
  servicestacks: IProtocolStack[];
}

export interface IProtocolFilter {
  lowerbound: string;
  upperbound: string;
  stackids: string[];
}

export interface IAppConfig {
  "client-id": string;
  "idp-well-known-configuration": string;
  "api-path-prefix"?: string;
}
