import { APIErrorProvider } from "./Dialogs/error/APIErrorProvider";
import { Theme } from "@mui/material/styles";
import { zebStyles as parentZebStyles } from "./theme/zebstyles";
import { showAdministration, showProtocol, showStatus } from "./utils/commonUtils";
import { useAuth } from "oidc-react";
import Administration from "./Administration";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import EnvironmentStates from "./EnvironmentStates";
import ErrorDialog from "./Dialogs/error/ErrorDialog";
import Journal from "./Journal";
import LoginLogoutUserProfile from "./LoginLogoutUserProfile";
import React from "react";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import ZebLogoIcon from "./ZebLogoIcon";
import makeStyles from "@mui/styles/makeStyles";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  ...parentZebStyles(theme),
}));

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function getCurrentTab(): number {
  const tab = localStorage.getItem("currenttab");
  return tab === null ? 0 : parseInt(tab);
}

export default function App() {
  const classes = useStyles();
  const [value, setValue] = React.useState(getCurrentTab());

  const auth = useAuth();

  const handleTabChange = (event: React.SyntheticEvent<Element, Event>, newValue: number) => {
    localStorage.setItem("currenttab", "" + newValue);
    setValue(newValue);
  };

  const MyTabs = () => {
    return (
      <Tabs value={value} onChange={handleTabChange} className={classes.tabs} indicatorColor="primary">
        {showStatus(auth) && <Tab label="Status" {...a11yProps(0)} />}
        {showProtocol(auth) && <Tab label="Protocol" {...a11yProps(1)} />}
        {showAdministration(auth) && <Tab label="Administration" {...a11yProps(2)} />}
      </Tabs>
    );
  };

  return (
    <div>
      <APIErrorProvider>
        <AppBar position="static" className={classes.appbar}>
          <Toolbar>
            <ZebLogoIcon size={2} className={classes.logo} />
            <Typography variant="h6" className={classes.tooltip}>
              Cloud Service Manager
            </Typography>
            <LoginLogoutUserProfile />
          </Toolbar>
          <MyTabs />
        </AppBar>

        {auth.userData ? (
          <>
            <TabPanel key={0} value={value} index={0}>
              <EnvironmentStates />
            </TabPanel>
            <TabPanel key={1} value={value} index={1}>
              <Journal />
            </TabPanel>
            <TabPanel key={2} value={value} index={2}>
              <Administration />
            </TabPanel>
            <ErrorDialog />
          </>
        ) : (
          <div>Please log in</div>
        )}
      </APIErrorProvider>
    </div>
  );
}
