import axios, { AxiosInstance } from "axios";

import { AuthContextProps } from "oidc-react/build/src/AuthContextInterface";
import {
  IAWSService,
  IAppConfig,
  IEnvironmentState,
  IEnvironmentStates,
  IEnvironments,
  IProtocolFilter,
  IProtocolStack,
  IRestResult,
  IServiceStack,
  IStartStopConfig,
  restResult,
} from "./restModel";
import { determineAPIUrl } from "../utils/environmentUtils";
import { stringfyomitkeys } from "../utils/commonUtils";

export const axiosInstance = axios.create({
  timeout: 60000,
  baseURL: determineAPIUrl(window.location),
});

const headers = {
  "Content-Type": "application/json",
};

function defineHeaderAxios(auth: AuthContextProps | null, axiosInstance: AxiosInstance) {
  if (auth === null) {
    console.warn("The authentication context is null!");
  } else {
    if (auth.userData) {
      const acessToken = auth.userData?.access_token;

      axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + acessToken;
    } else {
      console.warn("The authentication context is set but userdata is null!");
    }

    axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      function (error) {
        const originalRequest = error.config;
        if (!error.response) {
          return Promise.reject(error);
        }
        if (error.response.status === 401 && !originalRequest._retry) {
          console.debug("Performing re-authentication to get token ...");
          originalRequest._retry = true;

          auth.signIn();

          if (auth.userData) {
            console.debug("The re-authentication was successfull.");
            const acessToken = auth.userData?.access_token;

            console.debug("Performing second attempt ...");
            axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + acessToken;
            return axiosInstance(originalRequest);
          }
        }
        console.warn("Error while handling request: unable to get token for second request!");
        return Promise.reject(error);
      }
    );
  }
}

export async function getAllEnvironmentState(auth: AuthContextProps | null): Promise<IEnvironmentStates | null> {
  try {
    defineHeaderAxios(auth, axiosInstance);
    const response = await axiosInstance.get("/envctrl");
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getEnvironmentState(id: string, auth: AuthContextProps | null): Promise<IEnvironmentState | null> {
  try {
    defineHeaderAxios(auth, axiosInstance);
    const response = await axiosInstance.get("/envctrl/" + id);

    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getAllEnvironments(auth: AuthContextProps | null): Promise<IEnvironments | null> {
  try {
    defineHeaderAxios(auth, axiosInstance);
    const response = await axiosInstance.get("/envmgr");

    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getEnvironment(id: string, auth: AuthContextProps | null): Promise<IServiceStack | null> {
  try {
    defineHeaderAxios(auth, axiosInstance);
    const response = await axiosInstance.get("/envmgr/" + id);

    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function createEnvironment(
  envId: string,
  displayName: string,
  auth: AuthContextProps | null
): Promise<IRestResult> {
  const body = '{"stack_id":"' + envId + '", "stack_label":"' + displayName + '"}';

  try {
    //aquireLock
    defineHeaderAxios(auth, axiosInstance);
    const responseLock = await axiosInstance.post("/envmgr/editlock");

    if (responseLock.status === 200) {
      const response = await axiosInstance.post("/envmgr", body, {
        headers: headers,
      });
      if (response.status !== 200) {
        throw restResult(false, "Error while creating environment: " + response);
      }
    } else {
      return restResult(false, "Could not aquire lock. Please try again later");
    }
  } catch (error) {
    console.error(error);
    throw restResult(false, "Error while creating service: " + error);
  }

  return restResult(true, "Successfully created environment");
}

export async function deleteEnvironment(
  envId: string,
  displayname: string,
  auth: AuthContextProps | null
): Promise<IRestResult> {
  try {
    //aquireLock
    defineHeaderAxios(auth, axiosInstance);
    const responseLock = await axiosInstance.post("/envmgr/editlock");

    if (responseLock.status === 200) {
      const response = await axiosInstance.delete("/envmgr/" + envId);
      if (response.status !== 200) {
        throw restResult(false, "Error deleting environment");
      }
    } else {
      throw restResult(false, "Could not aquire lock. Please try again later");
    }
  } catch (error) {
    console.error(error);
  }

  return restResult(true, 'Successfully deleted environment "' + displayname + '"');
}

export async function stopEnvironment(
  envId: string,
  displayname: string,
  auth: AuthContextProps | null
): Promise<IRestResult> {
  try {
    defineHeaderAxios(auth, axiosInstance);
    const response = await axiosInstance.put("/envctrl/" + envId + "/stop");

    if (response.status !== 200) {
      throw restResult(false, "Error stopping environment. " + response.data.message);
    }
  } catch (error: any) {
    console.error(error);
    if (
      error.response.status == 400 &&
      error.response.data["message"].includes("is not stoppable by cloud service manager")
    ) {
      const instanceName = error.response.data["message"].split(" ")[0];

      throw restResult(
        false,
        `${instanceName} kann nicht über den Cloud Service Manager gestoppt werden, weil der Administrator diese Funktion gesperrt hat.`
      );
    } else if (
      error.response.status == 400 &&
      error.response.data["message"].includes("is not stoppable due to aws resource has started jobs")
    ) {
      const instanceName = error.response.data["message"].split(" ")[0];

      throw restResult(false, `${instanceName} kann aufgrund zurzeit laufender Jobs nicht gestoppt werden.`);
    } else {
      throw restResult(false, "Error while stopping environment: " + error);
    }
  }

  return restResult(true, 'Stopping environment "' + displayname + '". This may take a while.');
}

export async function startEnvironment(
  envId: string,
  displayname: string,
  auth: AuthContextProps | null
): Promise<IRestResult> {
  try {
    defineHeaderAxios(auth, axiosInstance);
    const response = await axiosInstance.put("/envctrl/" + envId + "/start");

    if (response.status !== 200) {
      throw restResult(false, "Error starting environment. " + response.data.message);
    }
  } catch (error) {
    console.error(error);
    throw restResult(false, "Error while starting environment: " + error);
  }

  return restResult(true, 'Starting environment "' + displayname + '". This may take a while. ');
}

export async function createServiceForEnvironment(
  envId: string,
  serviceName: string,
  instanceType: string,
  additional_settings: Record<string, unknown> | undefined,
  auth: AuthContextProps | null
): Promise<IRestResult> {
  let body;
  if (additional_settings) {
    body = `{
      "service_name":"${serviceName}", 
      "service_type":"${instanceType}", 
      "additional_settings": ${JSON.stringify(additional_settings)} 
    }`;
  } else {
    body = `{
      "service_name":"${serviceName}", 
      "service_type":"${instanceType}" 
    }`;
  }

  try {
    //aquireLock
    defineHeaderAxios(auth, axiosInstance);
    const responseLock = await axiosInstance.post("/envmgr/editlock");

    if (responseLock.status === 200) {
      //FIXME headers
      const response = await axiosInstance.post("/envmgr/" + envId, body, {
        headers: headers,
      });
      if (response.status !== 200) {
        throw restResult(false, "Error while creating service: " + response);
      }
    } else {
      return restResult(false, "Could not aquire lock. Please try again later");
    }
  } catch (error) {
    console.error(error);
    throw restResult(false, "Error while creating service: " + error);
  }

  return restResult(true, "Successfully created service");
}

export async function deleteService(
  envId: string,
  serviceName: string,
  auth: AuthContextProps | null
): Promise<IRestResult> {
  try {
    //aquireLock
    defineHeaderAxios(auth, axiosInstance);
    const responseLock = await axiosInstance.post("/envmgr/editlock");

    if (responseLock.status === 200) {
      const response = await axiosInstance.delete("/envmgr/" + envId + "/" + serviceName);
      if (response.status !== 200) {
        throw restResult(false, "Error while deleting service: " + response);
      }
    } else {
      throw restResult(false, "Could not aquire lock. Please try again later");
    }
  } catch (error) {
    console.error(error);
  }

  return restResult(true, "Successfully deleted service");
}

export async function updateService(
  envId: string,
  service: IAWSService,
  desiredCapacity: number,
  auth: AuthContextProps
): Promise<IRestResult> {
  try {
    //aquireLock
    defineHeaderAxios(auth, axiosInstance);
    const responseLock = await axiosInstance.post("/envmgr/editlock");

    if (responseLock.status === 200) {
      const payload = {
        service_name: service.servicename,
        service_type: service.servicetype,
        additional_settings: {
          desired_capacity: desiredCapacity,
        },
      };
      const response = await axiosInstance.put("/envmgr/" + envId + "/" + service.servicename, payload);
      if (response.status !== 200) {
        throw restResult(false, "Error while updating service: " + response);
      }
    } else {
      throw restResult(false, "Could not aquire lock. Please try again later");
    }
  } catch (error) {
    console.error(error);
    throw restResult(false, "Error while updating service: " + error);
  }
  return restResult(true, "Successfully updated service");
}

export async function createStartStopConfig(
  envId: string,
  startstopConfig: IStartStopConfig,
  auth: AuthContextProps | null
): Promise<IRestResult> {
  const body = JSON.stringify(stringfyomitkeys(startstopConfig, ["index"]));

  //aquireLock
  defineHeaderAxios(auth, axiosInstance);
  const responseLock = await axiosInstance.post("/envmgr/editlock").catch((responseLock) => {
    throw restResult(false, "Error while creating start/stop config: " + responseLock.data);
  });

  if (responseLock.status === 200) {
    await axiosInstance
      .post("/envmgr/" + envId + "/schedules/" + startstopConfig.index, body, {
        headers: headers,
      })
      .then((response) => {
        if (response.status !== 200) {
          throw restResult(false, "Error while creating service: " + response);
        }
        console.debug("Successfully created service. Response: " + response.data.message);
      })
      .catch((response) => {
        console.error("An error occured while trying to create service. Response: " + response);
        throw restResult(false, "Error while creating service: " + response.data);
      });
  } else {
    return restResult(false, "Could not aquire lock. Please try again later");
  }

  return restResult(true, "Successfully created service");
}

export async function updateStartStopConfig(
  envId: string,
  startstopConfig: IStartStopConfig,
  auth: AuthContextProps | null
): Promise<IRestResult> {
  const body = JSON.stringify(stringfyomitkeys(startstopConfig, ["index"]));

  //aquireLock
  defineHeaderAxios(auth, axiosInstance);
  const responseLock = await axiosInstance.post("/envmgr/editlock").catch((responseLock) => {
    throw restResult(false, "Error while updating start/stop config: " + responseLock.data);
  });

  if (responseLock.status === 200) {
    const response = await axiosInstance
      .put("/envmgr/" + envId + "/schedules/" + startstopConfig.index, body, {
        headers: headers,
      })
      .catch((response) => {
        console.error("An error occured while trying to update service. Response: " + response);
        throw restResult(false, "Error while updating start/stop config: " + response.response.data.exception);
      });
    if (response.status !== 200) {
      throw restResult(false, "Error updateing scheduler config");
    }
  } else {
    return restResult(false, "Could not aquire lock. Please try again later");
  }

  return restResult(true, "Successfully created scheduler config");
}

export async function loadProtocol(
  filter: IProtocolFilter,
  auth: AuthContextProps | null
): Promise<IProtocolStack[] | null> {
  try {
    defineHeaderAxios(auth, axiosInstance);

    const body = JSON.stringify(filter);
    const coeff = 1000 * 60 * 1; //for round to one minute

    const responseprot = await axiosInstance.post("/envrep", body, {
      headers: headers,
    });

    //set displaynames
    if (responseprot.data.stacks.length > 0) {
      const stacks = responseprot.data.stacks as IProtocolStack[];

      stacks.forEach((stack) => {
        //determine runtimes
        stack.runtimessumhours = 0;
        if (stack.runtimes.length > 0) {
          stack.runtimes.forEach((runtime) => {
            if (
              runtime.starttime !== null &&
              runtime.starttime !== undefined &&
              runtime.stoptime !== null &&
              runtime.stoptime !== undefined
            ) {
              let start = new Date(Date.parse(runtime.starttime));
              let stop = new Date(Date.parse(runtime.stoptime));

              start = new Date(Math.round(start.getTime() / coeff) * coeff);
              stop = new Date(Math.round(stop.getTime() / coeff) * coeff);

              runtime.starttime = start.toUTCString();
              runtime.stoptime = stop.toUTCString();

              const diffTimeHours = (Math.abs(stop.getTime() - start.getTime()) / (1000 * 60 * 60)) as number;
              //const diffTimeSeconds = (Math.abs(stop.getTime() - start.getTime()) / 1000) as number;
              runtime.durationhours = diffTimeHours;
              //var diffhours = Math.ceil(diffTime / (1000 * 60 * 60));
              stack.runtimessumhours = stack.runtimessumhours + diffTimeHours;
            }
          });
        }
      });

      stacks.sort(function (a, b) {
        const nameA = a.stackdisplayname.toUpperCase(); // Groß-/Kleinschreibung ignorieren
        const nameB = b.stackdisplayname.toUpperCase(); // Groß-/Kleinschreibung ignorieren
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // Namen müssen gleich sein
        return 0;
      });
      return stacks;
    }
  } catch (error: any) {
    const msg = error.response.data.message + ". " + error.response.data.exception;
    console.error(msg);
    throw restResult(false, "Error while loading protocol data: " + msg);
  }

  return null;
}

export async function getAppConfig(): Promise<IAppConfig | null> {
  try {
    const response = await axiosInstance.get("/config");
    return response.data;
  } catch (error: any) {
    console.error(error);
    throw error;
  }
}
