import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Tooltip } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { IAWSService } from "../api/restModel";
import { updateService } from "../api/restFacade";
import { useAuth } from "oidc-react";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import useAPIError from "./error/useAPIError";

type Props = {
  service: IAWSService;
  servicestackid: string;
  onSuccess: () => void;
};

const EditServiceDialog = ({ service, servicestackid, onSuccess }: Props) => {
  const auth = useAuth();

  const { enqueueSnackbar } = useSnackbar();
  const { addError } = useAPIError();

  const [open, setOpen] = useState<boolean>(false);
  const [desiredCapacity, setDesiredCapacity] = useState<number>(service.desired_capacity ?? 1);

  const handleClose = () => {
    setOpen(false);
    setDesiredCapacity(service.desired_capacity ?? 1);
  };

  const handleSaveClick = () => {
    // axios call
    updateService(servicestackid, service, desiredCapacity, auth)
      .then((res) => {
        enqueueSnackbar(res.message, { variant: "info" });
        onSuccess();
      })
      .catch((err) => {
        addError(err.message, "error");
      });
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Edit">
        <IconButton aria-label="edit" onClick={() => setOpen(true)}>
          <Edit />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle>Edit: {service.servicename}</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            id="desiredcapacity"
            label="Desired Capacity"
            type="number"
            variant="standard"
            InputProps={{ inputProps: { min: 1 } }}
            value={desiredCapacity}
            onChange={(event) => setDesiredCapacity(parseInt(event.target.value))}
            sx={{ mt: 1 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button disabled={desiredCapacity <= 0 || !desiredCapacity} onClick={handleSaveClick} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default EditServiceDialog;
