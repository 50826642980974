import { INSTANCETYPES, INSTANCETYPE_DEFAULT, instanceTypeValueByKey } from "../api/restModel";
import { Theme } from "@mui/material/styles";
import { zebStyles as parentZebStyles } from "../theme/zebstyles";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) => ({
  ...parentZebStyles(theme),

  formControlSelect: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(0),
    marginBottom: theme.spacing(4),
  },
  errorlabel: {
    fontSize: "15px",
    color: theme.palette.error.main,
  },
}));

export default function AddInstanceDialog(props: {
  label: string;
  callbackFunction: (
    instanceName: string,
    instanceType: string,
    additional_settings: Record<string, unknown> | undefined
  ) => void;
  callbackFunctionCheckServiceAlreadyAssigned: (nameofnewservice: string) => string | undefined;
}) {
  const [open, setOpen] = React.useState(false);

  const [instanceTypeSelectd, setInstanceTypeSelected] = React.useState(INSTANCETYPE_DEFAULT);
  const [instanceName, setInstanceName] = React.useState("");
  const [desiredCapacity, setDesiredCapacity] = React.useState(1);
  const [servicestacknameserviceavailable, setServicestacknameserviceavailable] = React.useState("");

  const classes = useStyles();

  const handleClickOpen = () => {
    setInstanceTypeSelected(INSTANCETYPE_DEFAULT);
    setServicestacknameserviceavailable("");
    setInstanceName("");
    setOpen(true);
  };

  const handleAdd = () => {
    let additional_settings: Record<string, unknown> | undefined = undefined;
    if (instanceTypeSelectd == "AUTOSCALING-GROUP" || instanceTypeSelectd == "KUBE-NODE-GROUP") {
      additional_settings = { desired_capacity: desiredCapacity };
    }
    props.callbackFunction(instanceName, instanceTypeSelectd, additional_settings);
    setOpen(false);
  };

  function ServiceAlreadyAssigned() {
    if (servicestacknameserviceavailable !== "") {
      return (
        <div>
          <FormLabel className={classes.errorlabel}>
            Service {instanceName} is already assigned in environment {servicestacknameserviceavailable}
          </FormLabel>
          <br />
        </div>
      );
    }
    return <div></div>;
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeInstanceName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInstanceName(event.target.value);
    setServicestacknameserviceavailable("");
  };
  const handleChangeDesiredCapacity = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDesiredCapacity(parseInt(event.target.value));
  };
  const handleChangeInstanceType = (event: SelectChangeEvent<INSTANCETYPES>) => {
    setInstanceTypeSelected(event.target.value as INSTANCETYPES);
  };
  const validateInstanceName = () => {
    if (instanceName.includes(" ")) {
      return [false, "The name of your resource contains a space. Spaces are not allowed in resource names."];
    }

    if (instanceTypeSelectd == INSTANCETYPES.APPSTREAM) {
      if (instanceName.length <= 0) {
        return [false, "Please provide the name of the AWS AppStream Fleet as service name."];
      }
    }

    if (instanceTypeSelectd == INSTANCETYPES.AUTOSCALING_GROUP) {
      if (instanceName.length <= 0) {
        return [false, "Please provide the name of the AWS Auto Scaling Group as service name."];
      }
    }

    if (instanceTypeSelectd == INSTANCETYPES.EC2) {
      if (instanceName.length <= 0) {
        return [false, "Please provide the name of the AWS EC2 instance as service name."];
      }
    }

    if (
      instanceTypeSelectd == INSTANCETYPES.K8S_CRD ||
      instanceTypeSelectd == INSTANCETYPES.K8S_DEPLOYMENT ||
      instanceTypeSelectd == INSTANCETYPES.K8S_STATEFUL_SET
    ) {
      if (instanceName.length <= 0) {
        return [
          false,
          <>
            Please provide the service name in the format: <br />
            [k8s-namespace]_[k8s-resource-name] <br /> <br />
            If you also want to specify a cluster-name, you can do this using the following format: <br />
            [k8s-namespace]_[k8s-resource-name]@[k8s-cluster-name] <br />
            If you do not specify a cluster-name, it will fallback to the default cluster.
          </>,
        ];
      }
      // check that the instance name contains a ":" and that it is not the last char of the name
      if (instanceName.indexOf("_") < 0 || instanceName.indexOf(":") == instanceName.length - 1) {
        return [false, "Service Name should include both k8s namespace and k8s resource name."];
      }
    }

    if (instanceTypeSelectd == INSTANCETYPES.KUBE_NODE_GROUP) {
      if (instanceName.length <= 0) {
        return [
          false,
          <>
            Please provide the service name in the format: <br />
            [EKS-Name]:[Node-Group-Name]
          </>,
        ];
      }
      // check that the instance name contains a ":" and that it is not the last char of the name
      if (instanceName.indexOf(":") < 0 || instanceName.indexOf(":") == instanceName.length - 1) {
        return [false, "Service Name should include both EKS-Name and NodeGroup-Name."];
      }
    }

    if (instanceTypeSelectd == INSTANCETYPES.RDS) {
      if (instanceName.length <= 0) {
        return [false, "Please provide the name of the RDS instance as service name."];
      }
    }

    return [true, ""];
  };

  return (
    <div>
      <Typography variant="subtitle1">{props.label}</Typography>
      <Tooltip title="Add Service">
        <IconButton
          className={classes.iconButton}
          size="small"
          edge="end"
          aria-label="add Instance"
          onClick={handleClickOpen}>
          <AddIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add AWS Instance</DialogTitle>
        <DialogContent style={{ display: "flex", flexDirection: "column" }}>
          <FormControl variant="standard" className={classes.formControlSelect} fullWidth>
            <InputLabel id="label-type">Type</InputLabel>
            <Select
              variant="standard"
              labelId="label-type"
              id="select-type"
              label="Type"
              style={{ width: 400 }}
              value={instanceTypeSelectd}
              onChange={handleChangeInstanceType}>
              {Object.values(INSTANCETYPES).map((type, index) => (
                <MenuItem key={index} value={type}>
                  {instanceTypeValueByKey(type)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            variant="standard"
            error={instanceName.length > 0 && !validateInstanceName()[0]}
            helperText={validateInstanceName()[1]}
            margin="dense"
            id="servicename"
            label="Service Name"
            type="text"
            autoFocus
            style={{ width: 400 }}
            value={instanceName}
            onChange={handleChangeInstanceName}
          />
          <ServiceAlreadyAssigned />
          {(instanceTypeSelectd == "AUTOSCALING-GROUP" || instanceTypeSelectd == "KUBE-NODE-GROUP") && (
            <div>
              <TextField
                variant="standard"
                margin="dense"
                id="desiredcapacity"
                label="Desired Capacity"
                type="number"
                InputProps={{ inputProps: { min: 1 } }}
                style={{ width: 400 }}
                value={desiredCapacity}
                onChange={handleChangeDesiredCapacity}
              />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button disabled={!validateInstanceName()[0]} onClick={handleAdd} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
