import { Theme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import useAPIError from "./useAPIError";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      borderColor: theme.palette.primary.main,
      margin: theme.spacing(0),
      color: theme.palette.grey[500],
      padding: theme.spacing(0),
    },
  })
);

export default function ErrorDialog() {
  const classes = useStyles();
  const error = useAPIError();

  const handleOk = () => {
    error.removeError();
  };

  return (
    <div>
      <Dialog open={error.showDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{(error.type = "error" ? "Error" : "Warning")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{error.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className={classes.button} onClick={handleOk} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
