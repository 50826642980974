import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { useAuth } from "oidc-react";
import React from "react";

type Props = {
  open: boolean;
  onClose: () => void;
};

const RolesDialog = ({ open, onClose }: Props) => {
  const auth = useAuth();

  const roles = (auth.userData?.profile.roles as string[]) ?? [];

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ mb: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
          <Typography variant="h5">Roles ({roles.length})</Typography>
          <IconButton onClick={onClose}>
            <CloseOutlined />
          </IconButton>
        </Box>
        <Typography>Roles you are currently mapped to by your administrator:</Typography>
      </DialogTitle>
      <DialogContent>
        {!roles ? <Typography>No roles</Typography> : roles.map((item) => <Typography key={item}>{item}</Typography>)}
      </DialogContent>
    </Dialog>
  );
};
export default RolesDialog;
