import { Theme } from "@mui/material/styles";
import { zebStyles as parentZebStyles } from "../theme/zebstyles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import React from "react";
import StopIcon from "@mui/icons-material/Stop";
import Tooltip from "@mui/material/Tooltip";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) => ({
  ...parentZebStyles(theme),
}));

export default function StopDialog(props: {
  type: string;
  index: number;
  name: string;
  callbackFunction: (index: number) => void;
}) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleOk = () => {
    props.callbackFunction(props.index);
    setOpen(false);
  };

  return (
    <div>
      <Tooltip title={props.type === "start" ? "Start Environment" : "Stop Environment"}>
        <IconButton
          aria-label="delete"
          onClick={handleClickOpen}
          className={props.type !== "start" ? classes.iconButtonGreen : classes.iconButtonRed}
          size="small">
          {props.type === "stop" ? <StopIcon /> : <PlayArrowIcon />}
        </IconButton>
      </Tooltip>

      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {" "}
          {props.type === "start" ? "Start Environment" : "Stop Environment"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.type === "start"
              ? 'Are you sure you want to start the evironment "' + props.name + '" ?'
              : 'Are you sure you want to stop the evironment "' + props.name + '" ?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleOk} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
