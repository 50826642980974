import { Theme } from "@mui/material/styles";
import { determineInitials } from "./utils/InitialUtils";
import { zebStyles as parentZebStyles } from "./theme/zebstyles";
import { useAuth } from "oidc-react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PersonIcon from "@mui/icons-material/Person";
import React, { useState } from "react";
import RolesDialog from "./components/RolesDialog/RolesDialog";
import Tooltip from "@mui/material/Tooltip";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) => ({
  ...parentZebStyles(theme),
  initial: {
    width: 40,
    height: 40,
    fontSize: 15,
    color: theme.palette.grey[100],
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

export default function LoginLogoutUserProfile() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const auth = useAuth();
  const classes = useStyles();

  const [rolesDialogOpen, setRolesDialogOpen] = useState<boolean>(false);
  const handleOpenRolesDialog = () => {
    setRolesDialogOpen(true);
    handleClose();
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    if (auth !== null) {
      auth.userManager.signoutRedirect();
      auth.userManager.removeUser();
    }
    handleClose();
  };

  const handleLogin = () => {
    if (auth !== null) {
      auth.signIn();
    }
  };

  const menuItems = () => {
    if (auth && auth.userData) {
      return (
        <>
          <MenuItem onClick={handleOpenRolesDialog}>Roles</MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </>
      );
    } else {
      return <MenuItem onClick={handleLogin}>Login</MenuItem>;
    }
  };

  function username(): string {
    return auth && auth.userData && auth.userData.profile && auth.userData.profile.name ? auth.userData.profile.name : "";
  }

  return (
    <div>
      <Tooltip title={username() != "" ? username() : "please log in"}>
        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
          <Avatar alt={username()} className={classes.initial}>
            {username() != "" ? determineInitials(username()) : <PersonIcon />}
          </Avatar>
        </Button>
      </Tooltip>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}>
        {menuItems()}
      </Menu>
      <RolesDialog
        open={rolesDialogOpen}
        onClose={() => {
          setRolesDialogOpen(false);
        }}
      />
    </div>
  );
}
